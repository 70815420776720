import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import ContentsTestimonials from "../../components/ContentsTestimonials";
import Conditions from "../../components/Conditions";
import Information from "../../components/Information";
import Map from "../../components/Map";

const PatientTestimonials = () => {
  return (
    <Layout>
      <PageHeader text="Patient Testimonials" />
      <ContentsTestimonials />
      <Conditions />
      <Information />
      <Map />
    </Layout>
  );
};

export default PatientTestimonials;
