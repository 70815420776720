import React from "react";

const ContentTestimonialItem = ({ name, children }) => {
  return (
    <div className="w-full lg:w-1/2 flex flex-col justify-center items-center mb-12">
      <p className="text-xl text-gray-600 mb-2">{children}</p>
      <p className="text-xl text-gray-700 self-end font-bold">{`- ${name}`}</p>
    </div>
  );
};

export default ContentTestimonialItem;
