import React from "react";
import ContentsTestimonialItem from "./ContentTestimonialItem";
import staffImage from "../img/testimonials.jpeg";

const ContentsTestimonials = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center pt-12 px-8">
      <img src={staffImage} alt="staff" className="w-84 max-w-full mb-10" />
      <div className="w-full lg:w-1/2">
        <p className="text-xl text-center text-gray-600 mb-16">
          As a team, we’re happy to serve you and make a difference in your
          life. Below are some of the testimonials we’ve received over the
          years.
        </p>
      </div>
      <ContentsTestimonialItem name="Judy B.">
        I sincerely thank you for all your care during my recent surgery. I am,
        indeed, blessed. Your due diligence in finding, and diagnosis of
        something so easily missed, have convinced me that I have an angel on my
        shoulder. And also your care during my recovery in the hospital is
        greatly appreciated. How fortunate I am to have found you to care for my
        medical needs. Coming from California and knowing no one here made it
        hard to know where to look. Thank you for being there for me.
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Peter, Annie, & Michael Y.">
        We deeply appreciate your help to save our son’s life. He has learned
        how to help & care about people. We will always remember all the friends
        & family that helped us this special year.
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Terrie V.">
        Just wanted you to know how much I appreciate you. No one else could
        have done what you did to make my family almost pain free. You are one
        in a million and just wanted you to know how much you are appreciated by
        me and I am sure many many more of your clients.
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Pat D.">
        I just want to take a few moments of your time to thank each of you for
        all your concern, help and support for my husband, Richard. I know that
        he, too, appreciated all the loving care each of you gave to him and
        also, all of the support and concern you gave me during this most
        difficult time. Thank you, again.
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Winifred J.">
        Thank you for taking such good care of me for all these years. You both
        (Dr. Fatemi and Dr. Zadeh) have kept me healthy and I will celebrate my
        90th birthday this year!
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Diane B.">
        I recently relocated to Boston from the Orlando area. As a long time
        patient of yours I’m writing to convey my heartfelt thanks as well as my
        gratitude to you for the outstanding care and consideration you gave me
        while I was a patient. To say it was exceptional, does not adequately
        describe it. Thanks again and best of luck.
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Carol F.">
        Thank you for all the attention you gave Frank during his many
        illnesses. Everyone went above and beyond to help him. He always felt
        comfortable and confident in your care. Thank you so much.
      </ContentsTestimonialItem>
      <ContentsTestimonialItem name="Barbara T.">
        I want to thank you for all the care and compassion you showed my Aunt.
        There are not many doctors today as wonderful as you. She is recovering
        well from your excellent care. Thank you.
      </ContentsTestimonialItem>
    </div>
  );
};

export default ContentsTestimonials;
